<template>
  <div>
    <component :is="appMode" />
  </div>
</template>
<script>
export default {
  components: {
    super: () => import('./components/Super'),
    staff: () => import('./components/Personal'),
  },
};
</script>
